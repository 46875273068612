import styled from "styled-components"
import { colors } from "./variables"

export const BudgetEstimateStyle = styled.div`
  .budgetEstimate {
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .budgetContent {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 80%;

      .left {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 15px;
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        padding: 15px;
      }
    }

    .titleBudget {
      color: ${colors.titleLightSmall};
      line-height: 28px;
    }
    .titleBudgetH1 {
      font-size: 6rem;
      line-height: 1.5;
      text-align: left;
    }

    .text {
      p {
        color: ${colors.darkGrey};
        margin-top: 25px;
        width: 29.3vw;
        line-height: 28px;
        text-align: left;
        font-family: "Barlow", sans-serif;
        font-weight: 400;
        font-size: 2.4rem;
      }
    }
    .btnBudget {
      background-color: ${colors.orange};
      color: #ffffff;
      border: none;
      box-shadow: none;
      border-radius: 0;
      font-family: "Barlow", sans-serif;
      font-size: 3rem;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      padding: 1.5em 3em;
      box-shadow: -0.5vw 0.5vw 1vw rgba(0, 0, 0, 0.15);
      cursor: pointer;
      transition: background-color 0.5s ease, box-shadow 0.5s ease;
      -o-transition: background-color 0.5s ease, box-shadow 0.5s ease;
      -moz-transition: background-color 0.5s ease, box-shadow 0.5s ease;
      -webkit-transition: background-color 0.5s ease, box-shadow 0.5s ease;

      &:hover {
        background-color: ${colors.orangeDark};
        box-shadow: -0.8vw 1vw 1.1vw rgba(0, 0, 0, 0.15);
      }

      &:focus {
        outline: 0;
      }

      div {
        display: inline-block;
      }
      p,
      i {
        display: table-cell;
        vertical-align: middle;
      }

      i {
        font-size: 2.6rem;
      }
    }
  }

  @media screen and (min-width: 2560px) {
    .budgetEstimate {
      .titleBudgetH1 {
        font-size: 8rem;
      }
      .text {
        p {
          font-size: 3.2rem;
        }
      }
    }
  }
  @media screen and (min-width: 1921px) and (max-width: 2559px) {
    .budgetEstimate {
      .titleBudgetH1 {
        font-size: calc(60px + 20 * (100vw - 1920px) / 640);
      }
      .text {
        p {
          font-size: calc(24px + 8 * (100vw - 1920px) / 640);
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .budgetEstimate {
      .titleBudgetH1 {
        font-size: 4.6rem;
      }
      .budgetContent {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-row-gap: 50px;

        .right {
          align-items: center;
        }
      }
      .text p {
        width: 100%;
      }
      .btnBudget {
        font-size: 1.5rem;
        white-space: nowrap;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .budgetEstimate {
      margin-bottom: 0;
      padding-top: 85px;
      padding-bottom: 85px;

      .titleBudgetH1 {
        font-size: 2.2rem;
        letter-spacing: 1.5px;
        margin: 0;
        line-height: 35px;
      }
      .budgetContent {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-row-gap: 50px;

        .right {
          align-items: center;
        }
      }
      .text {
        p {
          font-size: 1.4rem;
          line-height: 1.42;
          font-weight: 500;
          width: auto;
        }
      }
      .btnBudget {
        p {
          font-size: 1.4rem;
        }
        i {
          font-size: 2rem;
        }
      }
    }
  }
`
