import React from "react"
import { Link } from "gatsby"
import MainTitle from "../components/mainTitle"
import ScrollAnimation from "react-animate-on-scroll"

import { BudgetEstimateStyle } from "../styles/budgetEstimate"

const BudgetEstimate = props => (
  <BudgetEstimateStyle>
    <div className="budgetEstimate bgLighter">
      <div className="budgetContent">
        <div className="left">
          <ScrollAnimation animateIn="fadeInUp" offset={0} animateOnce={true}>
            <MainTitle
              bigText={props.bigText}
              bigText2={props.bigText2}
              colorBig={props.colorText}
              className={props.className}
              h1={props.h1}
            />
            <div className="text">
              <p>{props.firstParagraph}</p>
            </div>
          </ScrollAnimation>
        </div>
        <div className="right">
          <ScrollAnimation
            animateIn="fadeInUp"
            delay={250}
            offset={0}
            animateOnce={true}
          >
            <Link to="/orcamento">
              <button className="btnBudget">
                <div>
                  <p>Peça o seu orçamento&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <i className="material-icons-outlined">description</i>
                </div>
              </button>
            </Link>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  </BudgetEstimateStyle>
)

export default BudgetEstimate
