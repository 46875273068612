import styled from "styled-components"
import { colors, aspectratio } from "./variables"

export const CarouselProjectsStyle = styled.div`
  background-color: ${colors.darkGrey};

  div.carousel {
    height: ${aspectratio.carouselHeightClients};
    margin-right: 0;

    div.carousel__slider {
      height: 100%;
      align-items: center;
      display: grid;

      &:focus {
        outline: 0;
      }

      li.slide {
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img,
        p {
          display: block;
          margin-right: auto;
        }
      }
    }
  }

  .image {
    object-fit: cover;
    background-color: none;
    width: 65%;
    height: 60%;
    position: relative;

    .imgSlide {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
      position: relative;
      pointer-events: none;
      background-size: cover;
      background-position-x: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > * {
        z-index: 2;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
      }
    }
  }
  .slideDesc {
    width: 3vw;
    height: 2.5vw;
    box-shadow: -11px 8px 8.5px 0 rgba(0, 0, 0, 0.13);
    background-color: ${colors.orange};
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: -1.5vw;
    bottom: -1.25vw;
    z-index: 2;

    transition: background-color 0.5s ease, box-shadow 0.5s ease;
    -o-transition: background-color 0.5s ease, box-shadow 0.5s ease;
    -moz-transition: background-color 0.5s ease, box-shadow 0.5s ease;
    -webkit-transition: background-color 0.5s ease, box-shadow 0.5s ease;

    &:hover {
      background-color: ${colors.orangeDark};
    }

    i {
      color: #ffffff;
      font-size: 1.8rem;
    }
  }
  .slideOverlayText {
    width: 90%;
    height: auto;
    font-family: "Barlow", sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2em;
    letter-spacing: 0.05em;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
  }

  .no-content {
    color: white;
    font-family: "Barlow", sans-serif;
    font-size: 150%;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    width: calc(100vw - 127px);
  }

  .btnCarousel {
    width: 10.4vw;
    height: 3.23vw;
    box-shadow: -3px 6px 8.5px 0 rgba(0, 0, 0, 0.13);
    background-color: ${colors.orange};
    position: relative;
    left: 6vw;
    top: -1.75vw;

    button:focus {
      outline: 0;
    }
  }
  .btnCarouselSvgLeft {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    width: 50%;
    padding-left: 2vw;
    height: 100%;
    transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;

    &:hover {
      background-color: ${colors.orangeDark};
    }
  }
  .btnCarouselSvgRight {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    width: 50%;
    padding-right: 2vw;
    height: 100%;
    transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;

    &:hover {
      background-color: ${colors.orangeDark};
    }
  }

  .chevronCarousel {
    height: 50%;
    object-fit: contain;
  }

  @media screen and (min-width: 2560px) {
    .slideOverlayText{
      font-size: 2.4rem;
    }
  }
  @media screen and (min-width: 1921px) and (max-width: 2559px) {
    .slideOverlayText{
      font-size: calc(18px + 6 * (100vw - 1920px) / 640);
    }
  }
  @media screen and (max-width: 1500px) {
    .slideOverlayText{
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    div.carousel {
      height: 350px;
    }
    .slideDesc {
      width: 35px;
      height: 30px;
      right: -8.5px;
      bottom: -10px;
    }
    .slideOverlayText {
      font-size: 1rem;
    }
    .btnCarousel {
      width: 85px;
      height: 35px;
      left: 40px;
      top: -17.5px;
    }
    .no-content {
      width: calc(100vw - 60px);
      font-size: 100%;
    }
  }

  @media screen and (max-width: 415px) {
    div.carousel {
      height: 440px;
    }
  }
`
