import styled from "styled-components"
import { colors } from "./variables"

export const StartAreaStyle = styled.div`
  .startArea {
    position: relative;
    height: 65vh;
    min-height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    & > * {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .text {
      padding: 20vh 0px 0px 10vw;
      min-height: 100%;
    }

    h1 {
      color: #ffffff;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: "Barlow", sans-serif;
      font-size: 6rem;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3;
      letter-spacing: 1.04px;
    }

    p {
      color: #ffffff;
      margin-top: 25px;
      margin-bottom: 25px;
      max-width: 30%;
      font-family: "Barlow", sans-serif;
      font-size: 2.4rem;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.42;
      letter-spacing: normal;
      text-align: left;
    }

    /* Botões da primeira área */
    .buttons-container {
      display: flex;
      flex-wrap: wrap;
    }
    .buttons-container-grid {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: auto 1fr;
      grid-column-gap: 15px;
      grid-row-gap: 5px;
      margin-top: 20px;
      width: fit-content;
    }
    .button-wrapper {
      margin: 20px 20px 20px 0;
    }
    .button {
      display: inline-flex;
      box-sizing: border-box;
      height: 2em;
      width: fit-content;
      padding: 20px;
      white-space: nowrap;
      color: #ffffff;
      text-transform: uppercase;
      text-decoration: none;
      font-family: "Barlow", sans-serif;
      font-size: 1.6rem;
      @media only screen and (min-width: 2100px){
        font-size: 2.2rem;
      }
      font-weight: 600;
      letter-spacing: normal;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      background-color: ${colors.orange};
      position: relative;
      overflow: hidden;
      cursor: pointer;

      .link {
        color: #ffffff;
        text-decoration: none;
        letter-spacing: 1px;
        position: relative;
        transition: all 0.35s ease-Out;
      }
    }
    .slide {
      width: 100%;
      height: 100%;
      left: -100%;
      background: ${colors.orangeDark};
      position: absolute;
      transition: all 0.35s ease-Out;
      bottom: 0;
    }
    .button:hover .slide {
      left: 0;
    }
    .button:hover .link {
      color: #ffffff;
    }

    .tuv {
      width: 230px;
      grid-column-start: 2;
      grid-column-end: span 1;
      grid-row-start: 1;
      grid-row-end: span 2;

      img {
        width: 45%;
      }
    }
    /* Botões da primeira área */
  }
  @keyframes moveTooltip {
    0% {
      transform: translateX(-550px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  .tooltipArea {
    position: relative;
    left: calc(90% - 550px);
    z-index: 2;
    max-width: 550px;
    box-shadow: 0 11.5px 15px 0 rgba(0, 0, 0, 0.14);
    background-color: #f5f5f5;
    transform: translateY(-50%);
    padding: 5em;

    .text {
      overflow: hidden;
    }

    .textAnimation {
      animation: moveTooltip 1s 1;
    }

    h1 {
      font-family: "Monument";
      font-size: 3.5rem;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.32;
      letter-spacing: 1.25px;
      text-align: left;
      text-transform: uppercase;
      color: ${colors.titleLightSmall};
    }

    .content {
      font-family: "Barlow", sans-serif;
      font-size: 2rem;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.73;
      letter-spacing: 0.11px;
      text-align: left;
      color: ${colors.darkGrey};
      margin-top: 1em;
    }

    ul {
      list-style: circle;
      list-style-position: outside;
      font-family: "Barlow", sans-serif;
      font-size: 2rem;
      font-style: normal;
      font-weight: 300;
      color: ${colors.darkGrey};
    }

    .tooltipButtonContainer {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 4rem;
      right: 2.5vw;
      bottom: 0;
      transform: translateY(50%);
      background-color: ${colors.orange};
      color: #ffffff;
      border: none;
      box-shadow: -3px 6px 8.5px 0 rgba(0, 0, 0, 0.13);
      border-radius: 0;
      text-align: center;
      padding: 0.5em;

      transition: background-color 0.5s ease, box-shadow 0.5s ease;
      -o-transition: background-color 0.5s ease, box-shadow 0.5s ease;
      -moz-transition: background-color 0.5s ease, box-shadow 0.5s ease;
      -webkit-transition: background-color 0.5s ease, box-shadow 0.5s ease;

      &:hover {
        background-color: ${colors.orangeDark};
        box-shadow: -3px 6px 8.5px 0 rgba(0, 0, 0, 0.13);
      }
    }

    .tooltipButton {
      font-size: 1.6rem;
      @media only screen and (min-width: 2100px){
        font-size: 2.2rem;
      }
      width: auto;
      height: auto;
      font-family: "Barlow", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2em;
      letter-spacing: normal;
      text-align: center;

      .tooltipButtonArrow {
        fill: #ffffff;
        stroke: #ffffff;
        height: 0.8em;
        width: 25px;
        margin: 0 auto;
        transform: translate(0px);
        transition: transform 0.5s ease;
      }

      &:hover > .tooltipButtonArrow {
        transform: translate(5px);
      }
    }
  }
  .carouselProjectsContainer {
    padding: 0px 0px 100px 127px;
    transform: translateY(-5em);

    .title {
      margin-bottom: 2.55vw;
    }
  }

  @media screen and (min-width: 2560px) {
    .startArea{
      h1{
        font-size: 8rem;
      }
      p{
        font-size: 3.2rem;
      }
    }
  }
  @media screen and (min-width: 1921px) and (max-width: 2559px) {
    .startArea{
      h1{
        font-size: calc(60px + 20 * (100vw - 1920px) / 640);
      }
      p{
        font-size: calc(24px + 8 * (100vw - 1920px) / 640);
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .tooltipArea {
      h1 {
        font-size: 3rem;
      }
      p,
      ul {
        font-size: 1.8rem;
      }
      .tooltipButtonContainer {
        width: 155px;
        height: 40px;
        bottom: 0;
        right: 3vw;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .tooltipArea {
      width: 50%;
      height: unset;
      left: calc(90% - 550px);
      padding: 5em;
      transform: translateY(-25%);

      h1 {
        font-size: 2.5rem;
        max-width: 100%;
      }

      p,
      ul {
        font-size: 1.6rem;
      }

      .tooltipButtonContainer {
        width: 155px;
        height: 40px;
        bottom: 0;
        right: 5vw;
      }
    }
  }
  @media screen and (max-width: 860px) {
    .tooltipArea {
      width: 75%;
      left: calc(95% - 75%);
    }
  }
  @media screen and (max-width: 767px) {
    .startArea {
      height: 50vh;
      min-height: 400px;
    }
    .startArea .text {
      padding-left: 30px;
    }
    .startArea h1 {
      font-size: 2.4rem;
    }
    .startArea p {
      font-size: 1.3rem;
    }
    .startArea .button-wrapper {
      margin: 5px 20px 5px 0;
    }
    .startArea .buttons-container {
      width: 70%;
    }
    .startArea .button {
      font-size: 1rem;
      margin: 5px 5px 5px 0;
      padding: 10px 15px;
    }
    .tooltipArea {
      box-sizing: border-box;
      width: 100%;
      left: unset;
      max-width: 100%;
      padding: 5em 2em;
    }
    .tooltipArea .text h1 {
      font-size: 1.6rem;
      max-width: 80%;
    }
    .tooltipArea .text .content {
      font-weight: 400;
    }
    .tooltipArea .text .content p {
      font-size: 1.4rem;
      line-height: 18px;
    }
    .tooltipArea .text .content ul li {
      font-size: 1.4rem;
      font-weight: 400;
    }
    .tooltipArea .tooltipButtonContainer {
      width: 150px;
      height: 35px;
      bottom: 0;
      right: 25px;
    }
    .carouselProjectsContainer {
      padding: 60px 0px 60px 30px;

      .title {
        h1 {
          font-size: 2.5rem;
          line-height: 30px;
        }
        h4 {
          font-size: 1.2rem;
        }
      }
    }
  }
  @media screen and (max-width: 340px) {
    /*     .startArea .buttons-container-grid {
      grid-template-columns: auto;
      grid-template-rows: auto auto auto;
    } */
    .startArea .tuv {
      /*       grid-column-start: 1;
      grid-column-end: span 1;
      grid-row-start: 3;
      grid-row-end: span 1; */

      img {
        width: 20%;
      }
    }
  }
`
