import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"

import ChevronL from "../images/chevron_l.svg"
import ChevronR from "../images/chevron_r.svg"
import { CarouselProjectsStyle } from "../styles/carouselProjects"
import "pure-react-carousel/dist/react-carousel.es.css"

const CarouselProjects = props => {
  const data = useStaticQuery(graphql`
    query {
      manu: allContentfulCarouselProjects(
        filter: { branch: { eq: "manutencao" } }
      ) {
        totalCount
        edges {
          node {
            title
            slug
            image {
              file {
                url
              }
            }
          }
        }
      }
      ele: allContentfulCarouselProjects(
        filter: { branch: { eq: "eletricidade" } }
      ) {
        totalCount
        edges {
          node {
            title
            slug
            image {
              file {
                url
              }
            }
          }
        }
      }
      laser: allContentfulCarouselProjects(
        filter: { branch: { eq: "laser" } }
      ) {
        totalCount
        edges {
          node {
            title
            slug
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <CarouselProjectsStyle>
      <CarouselProvider
        naturalSlideWidth={props.naturalSlideWidth}
        naturalSlideHeight={props.naturalSlideHeight}
        visibleSlides={props.visibleSlides}
        totalSlides={
          props.area === "manutencao" && data.manu.totalCount !== 0
            ? data.manu.totalCount
            : props.area === "eletricidade" && data.ele.totalCount !== 0
            ? data.ele.totalCount
            : 4
        } /* {data.allContentfulCarouselProjects.totalCount} */
      >
        <Slider role="list">
          <>
            {props.area === "manutencao" ? (
              <>
                {data.manu.edges[0] ? (
                  data.manu.edges.map((edge, index) => {
                    return (
                      <Slide
                        role="listitem"
                        index={index}
                        key={index}
                        className="slide"
                      >
                        <div className="image">
                          <div
                            className="imgSlide"
                            style={{
                              backgroundImage: `url(${edge.node.image.file.url})`,
                            }}
                          >
                            <div className="slideOverlayText">
                              {edge.node.title}
                            </div>
                          </div>
                          <Link
                            aria-label={edge.node.title}
                            className="slideDesc"
                            to={`/projectos/${edge.node.slug}`}
                          >
                            <i className="material-icons">add</i>
                          </Link>
                        </div>
                      </Slide>
                    )
                  })
                ) : (
                  <Slide className="slide">
                    <div className="no-content">Conteúdos em breve...</div>
                  </Slide>
                )}
              </>
            ) : props.area === "eletricidade" ? (
              <>
                {data.ele.edges[0] ? (
                  data.ele.edges.map((edge, index) => {
                    return (
                      <Slide index={index} key={index} className="slide">
                        <div className="image">
                          <div
                            className="imgSlide"
                            style={{
                              backgroundImage: `url(${edge.node.image.file.url})`,
                            }}
                          >
                            <div className="slideOverlayText">
                              {edge.node.title}
                            </div>
                          </div>
                          <Link
                            aria-label={edge.node.title}
                            className="slideDesc"
                            to={`/projectos/${edge.node.slug}`}
                          >
                            <i className="material-icons">add</i>
                          </Link>
                        </div>
                      </Slide>
                    )
                  })
                ) : (
                  <Slide className="slide">
                    <div className="no-content">Conteúdos em breve...</div>
                  </Slide>
                )}
              </>
            ) : props.area === "laser" ? (
              <>
                {data.laser.edges[0] ? (
                  data.laser.edges.map((edge, index) => {
                    return (
                      <Slide index={index} key={index} className="slide">
                        <div className="image">
                          <div
                            className="imgSlide"
                            style={{
                              backgroundImage: `url(${edge.node.image.file.url})`,
                            }}
                          >
                            <div className="slideOverlayText">
                              {edge.node.title}
                            </div>
                          </div>
                          <Link
                            aria-label={edge.node.title}
                            className="slideDesc"
                            to={`/projectos/${edge.node.slug}`}
                          >
                            <i className="material-icons">add</i>
                          </Link>
                        </div>
                      </Slide>
                    )
                  })
                ) : (
                  <div className="no-content">Conteúdos em breve...</div>
                )}
              </>
            ) : (
              ""
            )}
          </>
        </Slider>
        <div className="btnCarousel">
          <ButtonBack className="btnCarouselSvgLeft">
            <ChevronL className="chevronCarousel" />
          </ButtonBack>
          <ButtonNext className="btnCarouselSvgRight">
            <ChevronR className="chevronCarousel" />
          </ButtonNext>
        </div>
      </CarouselProvider>
    </CarouselProjectsStyle>
  )
}

export default CarouselProjects
